<template>
  <div>
    <!-- 导航栏 -->
    <NavTop ref="navTop"></NavTop>
    <div class="upload-column">
      <div class="upload-column-box">
        <div class="box-left">
          <span class="box-left-title">发布作品</span>
          <span class="box-left-step">1.作品</span>
          <span class="box-left-step">2.信息</span>
          <span class="box-left-step">3.封面</span>
        </div>
        <div class="box-right">
          <div class="box-right-button" v-if="uploadParams.auditResult !== 2" @click="onSavePublishing(0)">保存为草稿</div>
          <div class="box-right-button" v-if="hasPerm('showings:works:push')" @click="onSavePublishing(1)">发布</div>
        </div>
      </div>
    </div>
    <div class="upload-content">
      <div class="upload-content-core">
        <div class="core-occupy"></div>
        <!--上传素材图片-->
        <div class="core-file">
          <div class="core-file-title">
            <div class="title">
              <span class="title-sign">*</span>
              <span class="title-text">上传图片</span>
            </div>
            <div class="explain">（该处为作品预览图片，图片格式png、jpg、jpeg，单张不超过10MB，建议图片比例16:9，数量不超过5张。）</div>
          </div>
          <div class="core-file-area">
            <div class="area-button">
              <div class="area-button-icon">
                <img src="@/assets/images/icons/img_icon.png" alt="">
              </div>
              <span>作品图片</span>
              <div class="area-button-upload">
                <UploadFile v-if="isEnforcement" ref="uploadImgs" :arr="uploadParams.materialPictureListArr" :width="160" :height="50" :isMultiple="true"
                            accept=".png,.jpg,.jpeg" :fileLength="5" fid="1623943134626025473">
                </UploadFile>
              </div>
            </div>
            <div class="area-show" v-if="uploadParams.materialPictureListArr.length > 0">
              <div class="area-show-file" v-for="(item,index) in uploadParams.materialPictureListArr" :key="index">
                <div class="image">
                  <img :src="item.url" alt="">
                </div>
                <div class="close" @click.stop="delFile(index,'uploadImgs')">
                  <img src="@/assets/images/btn/up_close.png" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--上传素材视频-->
        <div class="core-file">
          <div class="core-file-title">
            <div class="title">
              <span class="title-sign">*</span>
              <span class="title-text">上传视频</span>
            </div>
            <div class="explain">(视频格式mp4，单视频不超过100M)</div>
          </div>
          <div class="core-file-area">
            <div class="area-button">
              <div class="area-button-icon">
                <img src="@/assets/images/icons/video_icon.png" alt="">
              </div>
              <span>视频文件</span>
              <div class="area-button-upload">
                <UploadFile ref="uploadVideo" :arr="uploadParams.pathArr" :width="160" :height="50" accept=".mp4" :fileLength="1"
                            fid="1625031700689031169" @progressChange="getVideoProgress">
                </UploadFile>
              </div>
            </div>
            <div class="area-show" v-if="videoProgress > 0">
              <div class="area-show-video">
                <div class="icon">
                  <img src="@/assets/images/icons/up_play.png" alt="">
                </div>
                <div class="close" v-if="uploadParams.pathArr.length > 0" @click.stop="delFile(0,'uploadVideo')">
                  <img src="@/assets/images/btn/up_close.png" alt="">
                </div>
                <div class="progress">
                  <span :style="{'width': videoProgress + '%'}"></span>
                  <p>{{videoProgress}}%</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--上传素材包-->
        <div class="core-file">
          <div class="core-file-title">
            <div class="title">
              <span class="title-sign">*</span>
              <span class="title-text">素材包上传</span>
            </div>
            <div class="explain">(素材包格式zip，大小不超过8GB)</div>
            <div class="notice">
              <a href="https://oss.cycsmeta.com/file/230113/15/80682F743291A4D3AE4E19ABCB8B44F5.zip" target="_blank">
                <p>下载Demo</p>
              </a>
              <a href="https://oss.cycsmeta.com/docs/20230221/pak_build_doc.pdf" target="_blank">
                <p>《素材包打包说明》</p>
              </a>
            </div>
          </div>
          <div class="core-file-area">
            <div class="area-button">
              <div class="area-button-icon">
                <img src="@/assets/images/icons/file_icon.png" alt="">
              </div>
              <span>素材包上传</span>
              <div class="area-button-upload">
                <UploadFile ref="uploadZip" :arr="uploadParams.packageUrlArr" :width="160" :height="50" accept=".zip" :fileLength="1"
                            fid="1625046022454611970" @progressChange="getZipProgress" @pakSizeChange="getPakSize">
                </UploadFile>
              </div>
            </div>
            <div class="area-show" v-if="zipProgress > 0">
              <div class="area-show-zip">
                <div class="file">
                  <img src="@/assets/images/icons/zip_icon.png" alt="">
                </div>
                <div class="close" v-if="uploadParams.packageUrlArr.length > 0" @click.stop="delFile(0,'uploadZip')">
                  <img src="@/assets/images/btn/up_close.png" alt="">
                </div>
                <div class="progress">
                  <span :style="{'width': zipProgress + '%'}"></span>
                  <p>{{zipProgress}}%</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--素材信息填写-->
        <div class="core-info">
          <div class="core-info-fill">
            <div class="fill-title">
              <span class="fill-title-sign">*</span>
              <span class="fill-title-text">支持平台</span>
            </div>
            <div class="fill-multiple">
              <el-select v-model="uploadParams.supportPlatform" multiple placeholder="请选择支持平台">
                <el-option
                    v-for="(item,index) in plateList"
                    :key="index"
                    :label="item"
                    :value="item">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="core-info-fill">
            <div class="fill-title">
              <span class="fill-title-sign">*</span>
              <span class="fill-title-text">作品类型</span>
            </div>
            <div class="fill-dropdown">
              <el-select v-model="uploadParams.materialType" placeholder="请选择作品类型">
                <el-option label="场景作品" :value="0"></el-option>
                <el-option label="3D模型作品" :value="1"></el-option>
                <el-option label="其它作品" :value="2"></el-option>
              </el-select>
            </div>
          </div>
          <div class="core-info-fill">
            <div class="fill-title">
              <span class="fill-title-sign">*</span>
              <span class="fill-title-text">标题</span>
            </div>
            <div class="fill-name">
              <input type="text" maxlength="50" placeholder="请输入作品名称" v-model="uploadParams.materialName">
            </div>
          </div>
          <div class="core-info-fill">
            <div class="fill-title">
              <span class="fill-title-sign">*</span>
              <span class="fill-title-text">简介</span>
            </div>
            <div class="fill-introduction">
              <textarea maxlength="2000" placeholder="请输入作品简介" v-model="uploadParams.materialIntro"></textarea>
            </div>
          </div>
          <div class="core-info-fill">
            <div class="fill-title">
              <span class="fill-title-sign">*</span>
              <span class="fill-title-text">分类</span>
            </div>
            <div class="fill-dropdown">
              <el-select v-model="uploadParams.categoryId" placeholder="请选择作品分类">
                <el-option-group
                    v-for="group in categoryList"
                    :key="group.id"
                    :label="group.categoryName">
                  <el-option
                      v-for="item in group.children"
                      :key="item.id"
                      :label="item.categoryName"
                      :value="item.id">
                  </el-option>
                </el-option-group>
              </el-select>
            </div>
          </div>
          <div class="core-info-fill">
            <div class="fill-title">
              <span class="fill-title-sign">*</span>
              <span class="fill-title-text">封面</span>
            </div>
            <div class="fill-cover">
              <div class="fill-cover-show">
                <div class="show-vertical">
                  <div class="show-vertical-img" v-if="uploadParams.coverArr.length > 0">
                    <img :src="uploadParams.coverArr[0].url" alt="">
                  </div>
                  <div class="show-vertical-del" v-show="uploadParams.coverArr.length > 0" @click.stop="delFile(0,'uploadCover')">
                    <img src="@/assets/images/btn/up_close.png" alt="">
                  </div>
                  <div class="show-vertical-icon" v-show="uploadParams.coverArr.length === 0">
                    <img src="@/assets/images/icons/up_add.png" alt="">
                  </div>
                  <div class="show-vertical-upload">
                    <UploadFile ref="uploadCover" :arr="uploadParams.coverArr" :width="426.66" :height="240" accept=".png,.jpg,.jpeg" :fileLength="1"
                                fid="1625089642742255618">
                    </UploadFile>
                  </div>
                </div>
                <div class="vertical-text">横屏16:9</div>
              </div>
              <div class="fill-cover-show">
                <div class="show-horizontal">
                  <div class="show-horizontal-img" v-if="uploadParams.verticalCoverArr.length > 0">
                    <img :src="uploadParams.verticalCoverArr[0].url" alt="">
                  </div>
                  <div class="show-horizontal-del" v-show="uploadParams.verticalCoverArr.length > 0" @click.stop="delFile(0,'uploadVerticalCover')">
                    <img src="@/assets/images/btn/up_close.png" alt="">
                  </div>
                  <div class="show-horizontal-icon" v-show="uploadParams.verticalCoverArr.length === 0">
                    <img src="@/assets/images/icons/up_add.png" alt="">
                  </div>
                  <div class="show-horizontal-upload">
                    <UploadFile ref="uploadVerticalCover" :arr="uploadParams.verticalCoverArr" :width="135" :height="240" accept=".png,.jpg,.jpeg" :fileLength="1"
                                fid="1625089588631539713">
                    </UploadFile>
                  </div>
                </div>
                <div class="vertical-text">竖屏9:16</div>
              </div>
            </div>
          </div>
          <div class="core-info-fill" v-if="hasPerm('showings:works:set')">
            <div class="fill-title">
              <span class="fill-title-sign">*</span>
              <span class="fill-title-text">价格</span>
            </div>
            <div class="fill-input">
              <input type="number" placeholder="请输入价格" v-model="uploadParams.price">
            </div>
          </div>
          <div class="core-info-fill">
            <div class="fill-title">
              <span class="fill-title-sign">*</span>
              <span class="fill-title-text">guid</span>
            </div>
            <div class="fill-input">
              <input type="text" maxlength="35" placeholder="请输入作品guid" v-model="uploadParams.guid">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="upload-buttons">
      <div class="upload-buttons-left">
        <div class="button" v-if="hasPerm('showings:works:push')" @click="onSavePublishing(1)">发布</div>
        <div class="button" v-if="uploadParams.auditResult !== 2" @click="onSavePublishing(0)">保存为草稿</div>
      </div>
      <div class="upload-buttons-right">点击发布后大家即可欣赏你的作品了!</div>
    </div>
  </div>
</template>

<script>
import NavTop from "@/components/NavTop/NavTop.vue";
import UploadFile from "@/components/UploadFile/UploadFile.vue";
import homeApi from "@/api/home";
import userApi from "@/api/user";
import { cloneObj } from "@/utils/tools"
import {MessageBox,Loading} from "element-ui";

let defaultParams = {
  materialName: '', //素材名称
  materialType: '', //素材类型
  materialIntro: '', //素材介绍
  materialPictureList: [], //素材图片
  materialPictureListArr: [],
  path: '', //素材视频
  pathArr: [],
  packageUrl: '', //素材包
  packageUrlArr: [],
  pakSize: '', //素材包大小
  cover: '', //横屏封面
  coverArr: [],
  verticalCover: '', //竖屏封面
  verticalCoverArr: [],
  price: '', //素材价格
  guid: '',
  categoryId: '', //分类id
  userId: '',
  supportPlatform: [], //支持平台
  version: 'v1.0',
}

export default {
  components: {
    NavTop,
    UploadFile
  },
  data () {
    return {
      categoryList: [], //分类列表
      plateList: [], //平台列表
      uploadParams: cloneObj(defaultParams),
      videoProgress: 0, //视频上传进度
      zipProgress: 0, //ZIP包上传进度
      isEnforcement: false, // 详情接口是否执行完毕
    }
  },
  created() {
    this.getCategoryList(); //获取分类列表
    this.getPlateList(); //获取平台列表
    let materialId = parseInt(this.$route.query.materialId);
    if(materialId !== 0) {
      this.getEditWorksInfo(materialId);
    }else{
      this.isEnforcement = true;
    }
  },
  methods: {
    // 获取分类列表
    getCategoryList() {
      homeApi.getCategoryData().then(res => {
        this.categoryList = res.data.data;
      })
    },
    // 获取平台列表
    getPlateList() {
      homeApi.getPlateData().then(res => {
        this.plateList = res.data.data;
      })
    },
    // 获取编辑作品的信息
    getEditWorksInfo(id) {
      userApi.getWorksInfo({
        authorId: this.$store.state.userId,
        id
      }).then(res => {
        this.isEnforcement = true;
        let data = res.data.data;
        data.coverArr = [];
        if (data.cover) {
          data.coverArr.push({url: data.cover, fileName: data.cover});
        }
        data.verticalCoverArr = [];
        if (data.verticalCover) {
          data.verticalCoverArr.push({url: data.verticalCover, fileName: data.verticalCover});
        }
        data.pathArr = [];
        if (data.path) {
          data.pathArr.push({url: data.path, fileName: data.path});
          this.videoProgress = 100;
        }
        data.packageUrlArr = [];
        if (data.packageUrl) {
          data.packageUrlArr.push({url: data.packageUrl, fileName: data.packageUrl});
          this.zipProgress = 100;
        }
        data.materialPictureListArr = [];
        if (data.pictureVOList.length > 0) {
          data.pictureVOList.map(item => {
            if (item.picturePath.indexOf('.mp4') === -1) {
              data.materialPictureListArr.push({url: item.picturePath, fileName: item.picturePath});
            }
          })
        }
        this.uploadParams = cloneObj(data);
      })
    },
    // 选择分类
    handleSelectCategory(item) {
      this.uploadParams.categoryId = item.id;
      this.dropdownShow = false;
    },
    // 接收上传视频进度
    getVideoProgress(value) {
      this.videoProgress = value;
    },
    // 接收素材包进度
    getZipProgress(value) {
      this.zipProgress = value;
    },
    // 接收素材包大小
    getPakSize(value) {
      this.uploadParams.pakSize = value;
    },
    // 删除上传文件
    delFile(index,type) {
      if (type === 'uploadImgs') {
        this.$refs.uploadImgs.handleDelFile(index);
      }
      if (type === 'uploadVideo') {
        this.$refs.uploadVideo.handleDelFile(index);
        this.videoProgress = 0;
      }
      if (type === 'uploadZip') {
        this.$refs.uploadZip.handleDelFile(index);
        this.zipProgress = 0;
        this.uploadParams.pakSize = '';
      }
      if (type === 'uploadCover') {
        this.$refs.uploadCover.handleDelFile(index);
      }
      if (type === 'uploadVerticalCover') {
        this.$refs.uploadVerticalCover.handleDelFile(index);
      }
    },
    // 校验必填项
    onVerification() {
      if(this.uploadParams.materialPictureListArr.length === 0) {
        this.$message.error('请上传至少一张作品预览图片');
        return false;
      }
      if(this.uploadParams.pathArr.length === 0) {
        this.$message.error('请上传作品预览视频');
        return false;
      }
      if(this.uploadParams.packageUrlArr.length === 0) {
        this.$message.error('请上传作品素材包');
        return false;
      }
      if(this.uploadParams.supportPlatform.length === 0) {
        this.$message.error('请选择作品支持的平台');
        return false;
      }
      if(this.uploadParams.materialType === '') {
        this.$message.error('请选择作品类型');
        return false;
      }
      if(!this.uploadParams.materialName) {
        this.$message.error('请输入作品标题');
        return false;
      }
      if(!this.uploadParams.materialIntro) {
        this.$message.error('请输入作品简介');
        return false;
      }
      if(!this.uploadParams.categoryId) {
        this.$message.error('请选择作品分类');
        return false;
      }
      if(this.uploadParams.coverArr.length === 0) {
        this.$message.error('请上传作品横屏封面');
        return false;
      }
      if(!this.uploadParams.verticalCoverArr.length === 0) {
        this.$message.error('请上传作品竖屏封面');
        return false;
      }
      if(this.hasPerm('showings:works:set')) {
        if(this.uploadParams.price < 0 || this.uploadParams.price === '') {
          this.$message.error('请输入合适的作品价格');
          return false;
        }
      }
      if(!this.uploadParams.guid) {
        this.$message.error('请输入作品的guid');
        return false;
      }
      if(this.uploadParams.guid.length < 32 || this.uploadParams.guid.length > 32) {
        this.$message.error('请输入合规的作品guid');
        return false;
      }
      return true;
    },
    // 保存至草稿箱
    onSavePublishing(status) {
      if(this.onVerification()) {
        this.loadingInstance = Loading.service({
          lock: true,
          text: status===0?"创建作品中...":"发布作品中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        this.uploadParams.publishStatus = status; //设置作品状态: 0 草稿 1 发布
        this.uploadParams.userId = this.$store.state.userId;
        if (!this.uploadParams.id) {
          this.onCreateWorks();
        } else {
          this.onUpdateWorks();
        }
      }
    },
    // 创建作品接口
    onCreateWorks() {
      let moduleObj = cloneObj(this.uploadParams);
      moduleObj.cover = moduleObj.coverArr.length > 0 ? moduleObj.coverArr[0].url : '';
      moduleObj.verticalCover = moduleObj.verticalCoverArr.length > 0 ? moduleObj.verticalCoverArr[0].url : '';
      moduleObj.path = moduleObj.pathArr.length > 0 ? moduleObj.pathArr[0].url : '';
      moduleObj.packageUrl = moduleObj.packageUrlArr.length > 0 ? moduleObj.packageUrlArr[0].url : '';
      if (moduleObj.materialPictureListArr.length > 0) {
        moduleObj.materialPictureList = moduleObj.materialPictureListArr.map(item => {
          return {picturePath: item.url};
        })
      }
      delete moduleObj.coverArr;
      delete moduleObj.verticalCoverArr;
      delete moduleObj.pathArr;
      delete moduleObj.packageUrlArr;
      delete moduleObj.materialPictureListArr;
      userApi.postCreateWorks(moduleObj).then(res => {
        this.loadingInstance.close();
        if(res.data.code === 200) {
          MessageBox.confirm("操作成功", "温馨提示", {
            confirmButtonText: "确定",
            type: "success",
            closeOnClickModal: false,
            showClose: false,
            showCancelButton: false
          }).then(() => {
            window.opener = null;
            window.open("about:blank", "_top").close();
          })
        }else{
          this.$message.error(res.data.msg);
        }
      }).catch(err => {
        this.$message.error(err);
        this.loadingInstance.close();
      })
    },
    // 修改作品接口
    onUpdateWorks() {
      let moduleObj = cloneObj(this.uploadParams);
      moduleObj.cover = moduleObj.coverArr.length > 0 ? moduleObj.coverArr[0].url : '';
      moduleObj.verticalCover = moduleObj.verticalCoverArr.length > 0 ? moduleObj.verticalCoverArr[0].url : '';
      moduleObj.path = moduleObj.pathArr.length > 0 ? moduleObj.pathArr[0].url : '';
      moduleObj.packageUrl = moduleObj.packageUrlArr.length > 0 ? moduleObj.packageUrlArr[0].url : '';
      if (moduleObj.materialPictureListArr.length > 0) {
        moduleObj.materialPictureList = moduleObj.materialPictureListArr.map(item => {
          return {picturePath: item.url};
        })
      }
      delete moduleObj.coverArr;
      delete moduleObj.verticalCoverArr;
      delete moduleObj.pathArr;
      delete moduleObj.packageUrlArr;
      delete moduleObj.materialPictureListArr;
      console.log(moduleObj);
      userApi.postUpdateWorks(moduleObj).then(res => {
        console.log(res);
        this.loadingInstance.close();
        if(res.data.code === 200) {
          MessageBox.confirm("操作成功", "温馨提示", {
            confirmButtonText: "确定",
            type: "success",
            closeOnClickModal: false,
            showClose: false,
            showCancelButton: false
          }).then(() => {
            window.opener = null;
            window.open("about:blank", "_top").close();
          })
        }else{
          this.$message.error(res.data.msg);
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.upload-column {
  width: 100%;
  height: 93px;
  background: #0B0E0C;
  border-top: 2px solid #1D1D1D;
  box-sizing: border-box;
  position: fixed;
  top: 80px;
  left: 0;
  right: 0;
  z-index: 13;

  .upload-column-box {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .box-left {
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .box-left-title {
        font-weight: 700;
        font-size: 22px;
        color: #F9F9F9;
      }

      .box-left-step {
        padding: 0 20px;
        box-sizing: border-box;
        font-size: 18px;
        color: #F9F9F9;
      }

      .box-left-step:nth-child(2) {
        margin-left: 20px;
      }

      .box-left-step:nth-child(3),
      .box-left-step:nth-child(4) {
        border-left: 2px solid #979797;
      }
    }

    .box-right {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .box-right-button {
        width: 126px;
        height: 42px;
        border-radius: 5px;
        border: 1px solid #979797;
        box-sizing: border-box;
        font-size: 16px;
        font-weight: 400;
        color: #F9F9F9;
        line-height: 42px;
        text-align: center;
        cursor: pointer;
      }

      .box-right-button:last-child {
        margin-left: 15px;
        background-color: #FFF429;
        color: #0B0E0C;
        border: none;
      }
    }
  }
}

.upload-content {
  width: 100%;
  min-height: 100vh;
  margin-top: 173px;

  .upload-content-core {
    width: 1200px;
    margin: 0 auto;

    .core-occupy {
      width: 100%;
      height: 1px;
    }

    .core-file {
      width: 100%;
      margin-top: 60px;

      .core-file-title {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .title span {
          font-size: 16px;
          font-weight: 400;
          color: #F9F9F9;
        }

        .title .title-sign {
          color: #E52323;
        }

        .title .title-text {
          margin-left: 6px;
        }

        .explain {
          margin-left: 26px;
          font-size: 12px;
          font-weight: 400;
          color: #999999;
        }

        .notice {
          margin-left: 6px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          a {
            font-size: 12px;
            font-weight: 400;
            color: #999999;
          }

          a:hover {
            color: #FFF429;
          }
        }
      }

      .core-file-area {
        width: 100%;
        margin-top: 30px;
        background: rgba(53,57,54,0.2);
        border-radius: 5px;
        border: 1px dashed #979797;

        .area-button {
          width: 160px;
          height: 50px;
          margin: 40px auto;
          background: #FFF429;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;

          .area-button-icon {
            width: 25px;
            height: 25px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          span {
            display: block;
            margin-left: 6px;
            font-size: 16px;
            font-weight: 400;
            color: #0B0E0C;
          }

          .area-button-upload {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
          }
        }

        .area-show {
          width: 100%;
          padding: 0 40px 40px;
          box-sizing: border-box;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .area-show-file {
            width: 208px;
            height: 117px;
            //*13
            margin-right: 20px;
            position: relative;

            .image {
              width: 100%;
              height: 100%;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .close {
              width: 18px;
              height: 18px;
              position: absolute;
              top: 5px;
              right: 5px;
              cursor: pointer;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }

          .area-show-file:nth-of-type(5n) {
            margin-right: 0;
          }

          .area-show-video {
            width: 208px;
            height: 117px;
            //*13
            background: rgb(102,102,102);
            margin-right: 20px;
            position: relative;

            .icon {
              width: 64px;
              height: 64px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%,-60%);

              img {
                width: 100%;
                height: 100%;
              }
            }

            .close {
              width: 18px;
              height: 18px;
              position: absolute;
              top: 5px;
              right: 5px;
              cursor: pointer;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .play {
              width: 26px;
              height: 26px;
              position: absolute;
              left: 5px;
              bottom: 5px;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .progress {
              width: 180px;
              height: 6px;
              background: #666666;
              border-radius: 3px;
              position: absolute;
              left: 50%;
              bottom: 20px;
              transform: translateX(-50%);

              span {
                height: 6px;
                background: #FFFFFF;
                border-radius: 3px;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 9;
              }

              p {
                text-align: center;
                margin-top: 8px;
                color: #FFFFFF;
                font-size: 12px;
              }
            }
          }

          .area-show-zip {
            width: 208px;
            height: 117px;
            //*13
            margin-right: 20px;
            position: relative;

            .close {
              width: 18px;
              height: 18px;
              position: absolute;
              top: 5px;
              right: 5px;
              cursor: pointer;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .file {
              width: 80px;
              height: 80px;
              margin: 0 auto;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .progress {
              width: 180px;
              height: 6px;
              background: #666666;
              border-radius: 3px;
              position: absolute;
              left: 50%;
              bottom: 15px;
              transform: translateX(-50%);

              span {
                height: 6px;
                background: #FFFFFF;
                border-radius: 3px;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 9;
              }

              p {
                text-align: center;
                margin-top: 8px;
                color: #FFFFFF;
                font-size: 12px;
              }
            }
          }
        }
      }
    }

    .core-info {
      width: 100%;
      margin-top: 60px;

      .core-info-fill {
        width: 100%;
        margin-bottom: 60px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .fill-title {
          width: 80px;
          text-align: right;

          span {
            font-size: 16px;
            font-weight: 400;
            color: #F9F9F9;
          }

          .fill-title-sign {
            color: #E52323;
          }

          .fill-title-text {
            margin-left: 6px;
          }
        }

        .fill-name {
          width: 965px;
          height: 50px;
          margin-left: 16px;
          border-radius: 4px;
          border: 1px solid #979797;

          input {
            width: 100%;
            height: 100%;
            background: none;
            outline:none;
            border:none;
            line-height: 50px;
            padding: 0 25px;
            box-sizing: border-box;
            font-size: 16px;
            font-weight: 400;
            color: #E6E6E7;
          }
        }

        .fill-introduction {
          width: 965px;
          height: 145px;
          margin-left: 16px;
          border-radius: 4px;
          border: 1px solid #979797;

          textarea {
            width: 100%;
            height: 100%;
            background: none;
            outline:none;
            border:none;
            line-height: 20px;
            padding: 15px 25px;
            box-sizing: border-box;
            font-size: 16px;
            font-weight: 400;
            color: #E6E6E7;
            resize: none;
          }
        }

        .fill-multiple {
          width: 965px;
          min-height: 50px;
          margin-left: 16px;

          /deep/ .el-select {
            width: 100%;
            height: 100%;

            .el-input .el-input__inner {
              width: 100%;
              min-height: 50px;
              background: transparent;
              color: #E6E6E7;
              border: 1px solid #979797;
              border-radius: 4px;
              line-height: 50px;
              font-size: 16px;
            }
          }
        }

        .fill-dropdown {
          width: 300px;
          height: 50px;
          margin-left: 16px;

          /deep/ .el-select {
            width: 100%;
            height: 100%;

            .el-input .el-input__inner {
              width: 100%;
              height: 50px;
              background: transparent;
              color: #E6E6E7;
              border: 1px solid #979797;
              border-radius: 4px;
              line-height: 50px;
              font-size: 16px;
            }
          }
        }

        .fill-cover {
          margin-left: 16px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .fill-cover-show {
            margin-right: 60px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .show-vertical {
              width: 426.66px;
              height: 240px;
              border: 1px solid #979797;
              box-sizing: border-box;
              position: relative;

              .show-vertical-img {
                width: 100%;
                height: 100%;

                img {
                  width: 100%;
                  height: 100%;
                }
              }

              .show-vertical-del {
                width: 25px;
                height: 25px;
                position: absolute;
                top: 0;
                right: 0;
                cursor: pointer;
                z-index: 10;

                img  {
                  width: 100%;
                  height: 100%;
                }
              }

              .show-vertical-icon {
                width: 32px;
                height: 32px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);

                img {
                  width: 32px;
                  height: 32px;
                }
              }

              .show-vertical-upload {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
              }
            }

            .show-horizontal {
              width: 135px;
              height: 240px;
              border: 1px solid #979797;
              box-sizing: border-box;
              position: relative;

              .show-horizontal-img {
                width: 100%;
                height: 100%;

                img {
                  width: 100%;
                  height: 100%;
                }
              }

              .show-horizontal-del {
                width: 25px;
                height: 25px;
                position: absolute;
                top: 0;
                right: 0;
                cursor: pointer;
                z-index: 10;

                img  {
                  width: 100%;
                  height: 100%;
                }
              }

              .show-horizontal-icon {
                width: 32px;
                height: 32px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);

                img {
                  width: 100%;
                  height: 100%;
                }
              }

              .show-horizontal-upload {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
              }
            }

            .vertical-text {
              margin-top: 17px;
              font-size: 16px;
              font-weight: 400;
              color: #E6E6E7;
            }
          }
        }

        .fill-input {
          width: 300px;
          height: 50px;
          margin-left: 16px;
          border-radius: 4px;
          border: 1px solid #979797;

          input {
            width: 100%;
            height: 100%;
            background: none;
            outline:none;
            border:none;
            line-height: 50px;
            padding: 0 25px;
            box-sizing: border-box;
            font-size: 16px;
            font-weight: 400;
            color: #E6E6E7;
          }
        }
      }
    }
  }
}

.upload-buttons {
  width: 1200px;
  margin: 0 auto;
  padding: 50px 0 110px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .upload-buttons-left {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .button {
      width: 126px;
      height: 42px;
      border-radius: 5px;
      border: 1px solid #979797;
      box-sizing: border-box;
      font-size: 16px;
      font-weight: 400;
      color: #F9F9F9;
      line-height: 42px;
      text-align: center;
      cursor: pointer;
    }

    .button:first-child {
      margin-right: 15px;
      background-color: #FFF429;
      color: #0B0E0C;
      border: none;
    }
  }

  .upload-buttons-right {
    font-size: 18px;
    font-weight: 400;
    color: #E6E6E7;
  }
}
</style>